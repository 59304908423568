/* latin-ext */
@font-face {
  font-family: '__DM_Sans_88fdc4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/adde053269573569-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_88fdc4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3e3cf2ee9aa3f174-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_88fdc4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/adde053269573569-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_88fdc4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/3e3cf2ee9aa3f174-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_88fdc4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/adde053269573569-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_88fdc4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/3e3cf2ee9aa3f174-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_88fdc4';src: local("Arial");ascent-override: 94.37%;descent-override: 29.49%;line-gap-override: 0.00%;size-adjust: 105.12%
}.__className_88fdc4 {font-family: '__DM_Sans_88fdc4', '__DM_Sans_Fallback_88fdc4';font-style: normal
}

/* latin-ext */
@font-face {
  font-family: '__DM_Mono_8cd7b7';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/b9447d2328c4e1ad-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Mono_8cd7b7';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/d90e10e85a15eaad-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Mono_8cd7b7';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/67cc401a1d600c37-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Mono_8cd7b7';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/edb9f1eb1c1a7ead-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Mono_8cd7b7';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f6f4688957eedbfb-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Mono_8cd7b7';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/ce9b84dce7581e2b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Mono_Fallback_8cd7b7';src: local("Arial");ascent-override: 72.98%;descent-override: 22.81%;line-gap-override: 0.00%;size-adjust: 135.93%
}.__className_8cd7b7 {font-family: '__DM_Mono_8cd7b7', '__DM_Mono_Fallback_8cd7b7';font-style: normal
}

html,
body,
body > #__next,
body > #__next > main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

